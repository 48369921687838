
import { Component, Vue } from "vue-property-decorator";
import AppBar from "@/ui-components/AppBar/AppBar.vue";
import Logo from "@/ui-components/Logo/Logo.vue";
import MenuToggle from "@/ui-components/MenuToggle/MenuToggle.vue";

@Component({
  components: {
    AppBar, Logo, MenuToggle
  },
})
    
export default class AdminLayout extends Vue {}
